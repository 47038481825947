import React from "react";
import sebastian from "../../assets/images/Sebastian.png";
import canaryPointLogo from "../../assets/images/canaryPointLogo.png";
import SwiftAllied from "../../assets/images/Swift&Allied.png";
import Nairagram from "../../assets/images/Nairagram.png";
import Assets from "../../assets/images/Assets.png";
import InstantCash from "../../assets/images/InstantCash.png";
import MonieSTree from "../../assets/images/MonieSTree.png";
import "./Homepage.css";

const HomeSubsidiaries = () => {
  const logoPicture = [
    Assets,
    SwiftAllied,
    sebastian,
    Nairagram,
    InstantCash,
    MonieSTree,
  ];
  return (
    <div className="grand-container">
      <div className="container">
        <div className="logo-container">
          <div className="logo-sack">
            <img
              src={Assets}
              alt="sebastian"
              width="auto"
              height="auto"
              className="logo-img1"
            />
            <img
              src={SwiftAllied}
              alt="sebastian"
              width="auto"
              height="auto"
              className="logo-img2"
            />
            <img
              src={sebastian}
              alt="sebastian"
              width="auto"
              height="auto"
              className="logo-img3"
            />
            <img
              src={canaryPointLogo}
              alt="sebastian"
              width="auto"
              height="auto"
              className="logo-img4"
            />
            <img
              src={Nairagram}
              alt="sebastian"
              width="auto"
              height="auto"
              className="logo-img5"
            />
            <img
              src={InstantCash}
              alt="sebastian"
              width="auto"
              height="auto"
              className="logo-img6"
            />
            <img
              src={MonieSTree}
              alt="sebastian"
              width="auto"
              height="auto"
              className="logo-img7"
            />
          </div>
        </div>
        <div className="logo-scroll-container">
          {logoPicture.map((logo, index) => {
            return (
              <img
                src={logo}
                alt="sebastian"
                width="auto"
                height="auto"
                key={index}
                className="logo-scroll"
              />
            );
          })}
        </div>
        <div className="text-container">
          <h1>Our Subsidiaries</h1>
          <p>
            CPCSL is a holding company for various other companies that offer a
            range of investment and financial services to the public with the
            aim of offering effective money management
            <button className="showmore-btn">.…More</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeSubsidiaries;
