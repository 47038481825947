import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import HomePage from "./pages/HomePage";
import WhatWeDo from "./pages/WhatWeDo/WhatWeDo";
import AboutUs from "./pages/AboutUs";
import Subsidiaries from "./pages/Subsidiaries/Subsidiaries";
import CanaryFoundation from "./pages/CanaryFoundation";
import Careers from "./pages/Careers";
import Job from "../src/components/Careersfolder/Job";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import MediaPost from "./components/MediaFolder/MediaPost";
import Media from "./pages/Media";
import Blog from "./pages/Blog";
import News from "./pages/News";
import Events from "./pages/Events";
import Gallery from "./pages/Gallery";
import MediaGallery from "components/MediaFolder/MediaGallery";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/what-we-do" component={WhatWeDo} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/subsidiaries" component={Subsidiaries} />
          <Route exact path="/media/recent" component={Media} />
          <Route exact path="/media/blog" component={Blog} />
          <Route exact path="/media/news" component={News} />
          <Route exact path="/media/events" component={Events} />
          <Route exact path="/media/gallery" component={Gallery} />
          <Route
            exact
            path="/media/gallery/:galleryId"
            component={MediaGallery}
          />
          <Route exact path="/media/:prevPath/:postId" component={MediaPost} />
          <Route path="/canary-foundation" component={CanaryFoundation} />
          <Route path="/careers" component={Careers} />
          <Route path="/jobs" component={Job} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
