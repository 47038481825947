import React, { useState, useEffect } from "react";
import { carouselData, listenerCircle } from "./CarouselData";
import styles from "../Careersfolder/Careers.module.css";
import Container from "@material-ui/core/Container";
import LightSpeed from "react-reveal/LightSpeed";
import "./Active.css";

function Carousel() {
  const [active, setActive] = useState(0);

  const handleIndexClick = (event) => {
    let num = { active: +event.target.dataset.index };
    setActive(num.active);
  };

  useEffect(() => {
    const handleSlide = () => {
      if (active < carouselData.length - 1) {
        let id = setTimeout(() => {
          setActive(active + 1);
          clearTimeout(id);
        }, 10000);
      } else if (active >= carouselData.length - 1) {
        let idtwo = setTimeout(() => {
          setActive(0);
          clearTimeout(idtwo);
        }, 10000);
      }
    };
    handleSlide();
  }, [active]);
  return (
    <section className="backg-carousel" style={{ paddingBottom: "6rem" }}>
      <Container>
        <section className={styles.flexcontainercarousel}>
          <div className={styles.carouseltextarea}>
            <LightSpeed right>
              <div className={styles.textareaitems}>
                {" "}
                <p
                  className={styles.corevaluetext}
                  style={{ marginBottom: "0", paddingBottom: "0" }}
                >
                  <span>Colony</span> Members
                </p>
                <p className={styles.carouselinnertext}>
                  Don’t just take our word for it, hear what our current staff
                  have to say.
                </p>
                <button className={styles.carouselbtn}>Apply Now</button>
              </div>
            </LightSpeed>
          </div>
          <div className={styles.carousel}>
            <div className={styles.carouselflexitem}>
              <img
                className={styles.imgpaddingright}
                src={carouselData[active].img}
                width="auto"
                alt="carousel"
              ></img>
              <div className="text-container-color">
                <p className="carousel-text">{carouselData[active].text}</p>
                <div className="name-title">
                  <p className="carousel-name">{carouselData[active].name}</p>
                  <p className="carousel-title-position">
                    {carouselData[active].title}
                  </p>
                </div>
              </div>
            </div>

            <div className="carousel-listener-flex">
              {" "}
              {listenerCircle.map((cirlce, index) => (
                <div
                  id="circle-event-space"
                  key={index}
                  data-index={index}
                  onClick={handleIndexClick}
                  className={index === active ? "active" : "nonactive"}
                >
                  {cirlce}
                </div>
              ))}{" "}
            </div>
          </div>
        </section>
      </Container>
    </section>
  );
}

export default Carousel;
