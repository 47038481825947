import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Container from "@material-ui/core/Container";
import styles from "../../components/MediaFolder/Media.module.css";
import { linkedin, facebook, instagram, twitter } from "./Logo";
import TeamBondingEvent from "./TeamBondingEvent";
import HomeNewsLetter from "components/HomePageFolder/HomeNewsLetter";
import MediaBanner from "components/MediaFolder/MediaBanner";
import MediaNav from "./MediaNav";
import { useParams, Link } from "react-router-dom";

import { BASE_URL } from "../../config";

const MediaPost = ({ history }) => {
  const [blogData, setBlogData] = useState([]);
  const [blogResult, setBlogResult] = useState([]);

  const { postId } = useParams();
  const currentPathNav = history.location.pathname
    .replace("/media/", "")
    .replace("/" + postId, "");

  console.log(currentPathNav);

  let path;

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  useEffect(() => {
    window.scroll(0, 590);

    const getBlogPost = async () => {
      if (currentPathNav === "blog") {
        path = "blogs";
      } else if (currentPathNav === "news") {
        path = "articles";
      } else if (currentPathNav === "events") {
        path = "events";
      } else if (currentPathNav === "gallery") {
        path = "galleries";
      }

      try {
        const response = await axios.get(`${BASE_URL}/${path}/${postId}`);
        const data = await response.data;
        setBlogData(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBlogPost();
  }, [postId]);

  const getAllBlogPosts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/blogs`);
      const data = response.data;
      setBlogResult(data);
    } catch (error) {
      console.log(error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const truncate = (str, no_words) => {
    return str.split(" ").splice(0, no_words).join(" ");
  };

  // const currentPath = history.location.pathname.replace("/media/", "");
  // const currentPathNavIndex = history.location.pathname.lastIndexOf("/");

  return (
    <div>
      <MediaBanner
        s_active={capitalizeFirstLetter(currentPathNav)}
        active={blogData.title}
      />
      <MediaNav active={currentPathNav} />
      <Container style={{ marginTop: 70 }}>
        <div className={styles.teamBondingflex}>
          <div className={styles.teammajorcontent}>
            <div
              style={{
                display: "flex",
                height: "50px",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <p className={styles.headerTextMedia2}>{blogData.title}</p>
              <p className={styles.bondingDate}>
                {moment(blogData.published_at).format("Do MMMM, YYYY")}
              </p>
            </div>
            <img
              className={styles.postImageMedia}
              src={blogData.images && BASE_URL + blogData.images[0].url}
              alt="post-img-alt"
              width="100%"
              height="auto"
            />
            <p className={styles.teambondingtextmainone}>{blogData.body}</p>
            <div>
              <p className={styles.shareonsocial}>Share on social</p>
              <div className={styles.logoflexdisplay}>
                <div className={styles.logoteambonding}>{instagram.insta}</div>
                <div className={styles.logoteambonding}>{facebook.fbaook}</div>
                <div className={styles.logoteambonding}>{linkedin.in}</div>
                <div className={styles.logoteambonding}>{twitter.twt}</div>
              </div>
            </div>
          </div>
          <div className={styles.teamBondingsidebar}>
            <div className={styles.teamBondingsidebaritemone}>
              <p className={styles.topmedia}>Top Media</p>
              {blogResult.map((blog, i) => {
                if (i < 5) {
                  return (
                    <Link to={`/media/${currentPathNav}/${blog.id}`}>
                      <p key={i} className={styles.newproductrelease}>
                        {`${truncate(blog.title, 4)}....`}
                      </p>
                    </Link>
                  );
                }
              })}
            </div>
            <div className={styles.teamBondingsidebaritemtwo}>
              <p className={styles.teambodingads}>
                Internal<br></br> Advert Placement
              </p>
            </div>
          </div>
        </div>
      </Container>
      <TeamBondingEvent />
      <HomeNewsLetter />
    </div>
  );
};

export default MediaPost;
