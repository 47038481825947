import React from "react";
import "./WhatWeDoTabHome.css";
import InvestMentBanking from "../../assets/images/investmentBankingImg.png";
import Microfinancebanking from "../../assets/images/microFinanceBankingImg.png";
import BureaudeChange from "../../assets/images/bdc_Img.png";
import remittanceImg from "../../assets/images/remittanceImg.png";
import product from "../../assets/images/products.png";
export const InvestmentBanking = () => {
  return (
    <div>
      <div>
        <p className="investmentbanking-text">
          Our highly-skilled Investment Banking team work across various asset
          classes, industries and currencies to provide first class investment
          services.<button className="fondtout-more-btn">Find out more</button>
        </p>
      </div>
      <div>
        <img
          src={InvestMentBanking}
          alt="investmentBanking"
          width="100%"
          className="img-radius"
        />
      </div>
    </div>
  );
};
export const MicrofinanceBanking = () => {
  return (
    <div>
      <div>
        <p className="microfinancebanking-text">
          Our highly-skilled Investment Banking team work across various asset
          classes, industries and currencies to provide first class investment
          services. <button className="fondtout-more-btn">Find out more</button>
        </p>
      </div>
      <div>
        <img
          src={Microfinancebanking}
          alt="investmentBanking"
          width="100%"
          className="img-radius"
        />
      </div>
    </div>
  );
};
export const Remittance = () => {
  return (
    <div>
      {" "}
      <div>
        <p className="remittance-text">
          We understand that the Nigerian community needs to send money home and
          we have created an instant payment gateway.{" "}
          <button className="fondtout-more-btn">Find out more</button>
        </p>
      </div>
      <div>
        <img
          src={remittanceImg}
          className="img-radius"
          alt="investmentBanking"
          width="100%"
        />
      </div>
    </div>
  );
};
export const BureauDeChange = () => {
  return (
    <div>
      {" "}
      <div>
        <p className="bureaudechange-text">
          Our Bureau De Change business is tailored to meet the needs of our
          clients, need for speed and convenience. We are regulated by the CBN…
          <button className="fondtout-more-btn">Find out more</button>
        </p>
      </div>
      <div>
        <img
          src={BureaudeChange}
          alt="investmentBanking"
          width="100%"
          className="img-radius"
        />
      </div>
    </div>
  );
};
export const Product = () => {
  return (
    <div>
      <div>
        <p className="product-text">
          We are constantly innovating to provide the best finacial products in
          the market. Our current product offerings range from loans to savings
          and payments.{" "}
          <button className="fondtout-more-btn">Find out more</button>
        </p>
      </div>
      <div>
        <img
          src={product}
          alt="investmentBanking"
          width="100%"
          className="img-radius"
        />
      </div>
    </div>
  );
};
