import recentTeamone from "../../assets/images/recentTeamone.png";
import recentTeamtwo from "../../assets/images/recentTeamtwo.png";
import recentTeamthree from "../../assets/images/recentTeamthree.png";
import blankImage from "../../assets/images/blankImage.png";
export const recentmediadata = [
  {
    img: recentTeamthree,
    title: "New Product Release",
    text:
      "We are proud to announce the release of the latest savings and investment product with Canary Point services",
    name: "new product release",
    id: 1,
  },
  {
    img: recentTeamtwo,
    title: "AMFB on the Rise",
    text:
      "Microfinance banking has a new face. The Assests Microfinace group is seeting out with Canary Point services",
    name: "AMFB on the rise",
    id: 2,
  },
  {
    img: recentTeamone,
    title: "Team Bonding",
    text:
      "At Canary, we prioritize the well-being of our staff and place a high priority of company culture with Canary Point services",
    name: "Team Bonding",
    id: 3,
  },
  {
    img: blankImage,
    title: "New Product Release",
    text:
      "We take the hassle out of Finance - whether you are a business or an individual",
    name: "Team Bonding",
    id: 4,
  },
  {
    img: blankImage,
    title: "New Product Release",
    text:
      "We take the hassle out of Finance - whether you are a business or an individual",
    name: "Team Bonding",
    id: 5,
  },
  {
    img: blankImage,
    title: "New Product Release",
    text:
      "We take the hassle out of Finance - whether you are a business or an individual",
    name: "Team Bonding",
    id: 6,
  },
  {
    img: blankImage,
    title: "New Product Release",
    text:
      "We take the hassle out of Finance - whether you are a business or an individual",
    name: "Team Bonding",
    id: 7,
  },
  {
    img: blankImage,
    title: "New Product Release",
    text:
      "We take the hassle out of Finance - whether you are a business or an individual",
    name: "Team Bonding",
    id: 8,
  },
  {
    img: blankImage,
    title: "New Product Release",
    text:
      "We take the hassle out of Finance - whether you are a business or an individual",
    name: "Team Bonding",
    id: 9,
  },
];
