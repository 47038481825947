import React, { useState, useEffect } from "react";
import HomeNewsLetter from "components/HomePageFolder/HomeNewsLetter";
import MediaBanner from "components/MediaFolder/MediaBanner";
import MediaNav from "components/MediaFolder/MediaNav";
import RecentCardMedia from "components/MediaFolder/RecentCardMedia";
import Container from "@material-ui/core/Container";
import styles from "components/HomePageFolder/Homepage.module.css";
import stylesmedia from "components/MediaFolder/Media.module.css";

import axios from "axios";
import { BASE_URL } from "../config";

const Gallery = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [indexCount, setIndexCount] = useState(6);

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  const getAllBlogPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/galleries`);
      const data = response.data;
      setBlogData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const truncate = (str, no_words) => {
    return str.split(" ").splice(0, no_words).join(" ");
  };

  return (
    <div>
      <MediaBanner active="Gallery" />
      <MediaNav active="gallery" />
      <div className={styles.recentcontainer}>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
            }}
          >
            <h3 style={{ color: "#666" }}>Loading...</h3>
          </div>
        )}
        {blogData.length && (
          <Container>
            <div className={stylesmedia.flexcontainerrecentcard}>
              {blogData.map((blog, index) => {
                const imageUrl = BASE_URL + blog.images[0].url;
                if (index < indexCount) {
                  return (
                    <RecentCardMedia
                      linkTo={`/media/gallery/${blog.id}`}
                      key={index}
                      id={blog.id}
                      title={truncate(blog.title, 4)}
                      body={blog.body}
                      image={imageUrl}
                    />
                  );
                }
              })}
            </div>
            {blogData.length > 6 && (
              <>
                {indexCount <= 6 && (
                  <button
                    onClick={() => setIndexCount(blogData.length)}
                    className={styles.viewmorebtn}
                  >
                    View More
                  </button>
                )}
                {indexCount > 6 && (
                  <button
                    onClick={() => setIndexCount(6)}
                    className={styles.viewmorebtn}
                  >
                    View Less
                  </button>
                )}
              </>
            )}
          </Container>
        )}
        {!blogData && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 50,
            }}
          >
            <h3 style={{ color: "#666" }}>
              No Content Available, check back later !
            </h3>
          </div>
        )}
      </div>
      <HomeNewsLetter />
    </div>
  );
};

export default Gallery;
