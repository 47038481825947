import React from "react";
// import axios from "axios";
import CanaryLogo from "assets/img/whatwedo-img/canaryPointLogo.png";
// import Logo1 from "assets/img/whatwedo-img/companyLogo1.png";
import Logo2 from "assets/img/whatwedo-img/companyLogo2.png";
import Logo3 from "assets/img/whatwedo-img/companyLogo3.png";
import Logo4 from "assets/img/whatwedo-img/companyLogo4.png";
import Logo5 from "assets/img/whatwedo-img/companyLogo5.png";
import Logo6 from "assets/img/whatwedo-img/companyLogo6.png";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import style from "./SubsidiariesStyle";
import {
  Content1,
  Content2,
  Content3,
  Content4,
  Content5,
  Content6,
} from "./LogosContent";
import CareerJobForm from "components/Careersfolder/CareerJobForm";

const useStyles = makeStyles(style);

function getLogoContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <Content2 />;
    case 1:
      return <Content2 />;
    case 2:
      return <Content3 />;
    case 3:
      return <Content4 />;
    case 4:
      return <Content5 />;
    case 5:
      return <Content6 />;
    default:
      return <Content1 />;
  }
}

const Subsidiaries = () => {
  const classes = useStyles();
  // const [email, setEmail] = useState("");
  // const [success, setSuccess] = useState([]);
  const [activeLogoContent, setActiveLogoContent] = React.useState(0);

  const handleLogoContent = (event) => {
    event.preventDefault();
    // console.log(event);
    const { alt } = event.target;
    if (alt) {
      /* if (alt === "subsidiariesLogo1") {
        setActiveLogoContent(0);
      } else */ if (
        alt === "subsidiariesLogo2"
      ) {
        setActiveLogoContent(1);
      } else if (alt === "subsidiariesLogo3") {
        setActiveLogoContent(2);
      } else if (alt === "subsidiariesLogo4") {
        setActiveLogoContent(3);
      } /*else if (alt === "subsidiariesLogo5") {
        setActiveLogoContent(4);
      }*/ else if (
        alt === "subsidiariesLogo6"
      ) {
        setActiveLogoContent(5);
      } else {
        setActiveLogoContent(1);
      }
    }
  };

  // const handleChange = (event) => {
  //   setEmail(event.target.value);
  // };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post("/", email)
  //     .then((response) => setSuccess(response))
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  //   setEmail("");
  // };
  // console.log(success);
  return (
    <div>
      <Container>
        <Grid container className={classes.root}>
          <Grid item xs={12} md={10} className={classes.SubsidWrapper}>
            <Grid container className={classes.SubsidiariesHeader}>
              <Grid item className={classes.CanaryLogo}>
                <img src={CanaryLogo} width="100%" alt="canary logo" />
              </Grid>
              <Grid
                item
                xs={11}
                sm={5}
                md={6}
                className={classes.SubsidiariesHeadGrid}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.typographyH5}
                >
                  Our Subsidiaries
                </Typography>
                <Typography className={classes.typographybody1}>
                  Canary Point Holdings is a holding company for various other
                  companies that offer a range of investment and financial
                  services to the public with the aim of offering effective
                  money management
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.SubsidLogoWrapper}>
            <Grid container className={classes.subLogosDiv}>
              {/* <Grid item className={classes.subLogoGrid}>
                <img
                  onClick={handleLogoContent}
                  src={Logo1}
                  alt="subsidiariesLogo1"
                  className={
                    activeLogoContent === 0 ? classes.subLogo : classes.subLogo2
                  }
                />
              </Grid> */}
              <Grid item className={classes.subLogoGrid}>
                <img
                  onClick={handleLogoContent}
                  src={Logo2}
                  alt="subsidiariesLogo2"
                  className={
                    activeLogoContent === 1 ? classes.subLogo : classes.subLogo2
                  }
                />
              </Grid>
              <Grid item className={classes.subLogoGrid}>
                <img
                  onClick={handleLogoContent}
                  src={Logo3}
                  alt="subsidiariesLogo3"
                  className={
                    activeLogoContent === 2 ? classes.subLogo : classes.subLogo2
                  }
                />
              </Grid>
              <Grid item className={classes.subLogoGrid}>
                <img
                  onClick={handleLogoContent}
                  src={Logo4}
                  alt="subsidiariesLogo4"
                  className={
                    activeLogoContent === 3 ? classes.subLogo : classes.subLogo2
                  }
                />
              </Grid>
              {/* <Grid item className={classes.subLogoGrid}>
                <img
                  onClick={handleLogoContent}
                  src={Logo5}
                  alt="subsidiariesLogo5"
                  className={
                    activeLogoContent === 4 ? classes.subLogo : classes.subLogo2
                  }
                />
              </Grid> */}
              <Grid item className={classes.subLogoGrid}>
                <img
                  onClick={handleLogoContent}
                  src={Logo6}
                  alt="subsidiariesLogo6"
                  className={
                    activeLogoContent === 5 ? classes.subLogo : classes.subLogo2
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>{getLogoContent(activeLogoContent)}</Grid>
        </Grid>
        <CareerJobForm />
      </Container>
    </div>
  );
};

export default Subsidiaries;
