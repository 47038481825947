import React, { useState, useEffect } from "react";
import "./Homepage.css";
import {
  Product,
  BureauDeChange,
  Remittance,
  MicrofinanceBanking,
  InvestmentBanking,
} from "./WhatWeDoTabHome";

function getLogoContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <InvestmentBanking />;
    case 1:
      return <MicrofinanceBanking />;
    // case 2:
    //   return <Remittance />;
    // case 3:
    //   return <BureauDeChange />;
    case 3:
      return <Product />;
    default:
      return <InvestmentBanking />;
  }
}

const WhatWeDoHome = () => {
  const [activeLogoContent, setActiveLogoContent] = useState(0);

  const handleLogoContent = (event) => {
    event.preventDefault();
    const { className } = event.target;
    if (className) {
      if (className === "1") {
        setActiveLogoContent(0);
      } else if (className === "2") {
        setActiveLogoContent(1);
      } else if (className === "3") {
        setActiveLogoContent(2);
        // } else if (className === "4") {
        //   setActiveLogoContent(3);
        // } else if (className === "5") {
        //   setActiveLogoContent(4);
      } else {
        setActiveLogoContent(0);
      }
    }
  };
  useEffect(() => {
    if (
      activeLogoContent === 0 ||
      activeLogoContent === 1 ||
      activeLogoContent === 2 ||
      activeLogoContent === 3 ||
      activeLogoContent === 4
    ) {
      let timeOutId = setInterval(() => {
        setActiveLogoContent((prev) => prev + 1);
        clearTimeout(timeOutId);
      }, 9000);
    }
  }, [activeLogoContent]);

  useEffect(() => {
    if (activeLogoContent === 4) {
      let timeOutId = setInterval(() => {
        setActiveLogoContent(0);
        clearTimeout(timeOutId);
      }, 12000);
    }
  }, [activeLogoContent]);

  return (
    <div className="grand-containertwo">
      <div className="tabpanelgrand">
        <div className="content-body">
          <div className="what-we-do-color">
            <h3>
              <strong>What</strong> We <strong>Do</strong>
            </h3>
            <div className="underline"></div>
          </div>

          <div className="border-rectangle">
            <div className="tabhome-panelcontainer">
              <div
                className={
                  activeLogoContent === 0 ? "activeness" : "tab-panelone"
                }
              >
                <div>
                  <p onClick={handleLogoContent} className="1">
                    Investment Banking
                  </p>
                </div>
              </div>
              <div
                className={
                  activeLogoContent === 1 ? "activeness" : "tab-paneltwo"
                }
              >
                <div>
                  <p onClick={handleLogoContent} className="2">
                    Microfinance Banking
                  </p>
                </div>
              </div>
              {/* <div
                className={
                  activeLogoContent === 2 ? "activeness" : "tab-panelthree"
                }
              >
                <div>
                  <p onClick={handleLogoContent} className="3">
                    Remittance
                  </p>
                </div>
              </div> */}
              {/* <div
                className={
                  activeLogoContent === 3 ? "activeness" : "tab-panelfour"
                }
              >
                <div>
                  <p onClick={handleLogoContent} className="4">
                    Bureau de Change
                  </p>
                </div>
              </div> */}

              <div
                className={
                  activeLogoContent === 3 ? "activeness" : "tab-panelfive"
                }
              >
                <div>
                  <p onClick={handleLogoContent} className="3">
                    Products
                  </p>
                </div>
              </div>
            </div>
            <div className="tab-content">
              {getLogoContent(activeLogoContent)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhatWeDoHome;
