import React, { useState } from "react";
import AboutBanner from "components/About/AboutBanner";
import AboutUsSection from "components/About/AboutUsSection";
import VisionMission from "components/About/VisionMission";
import OurPeople from "components/About/OurPeople";
import CareerJobForm from "components/Careersfolder/CareerJobForm";

const AboutUs = () => {
  const [toogleourpeople, setToogleourpeople] = useState(true);
  const handleTooglepeople = () => {
    setToogleourpeople((prev) => !prev);
  };
  return (
    <div>
      <AboutBanner toogleourpeople={toogleourpeople} />
      <AboutUsSection />
      <VisionMission />
      <OurPeople
        toogleourpeople={toogleourpeople}
        handleTooglepeople={handleTooglepeople}
      />
      <CareerJobForm />
    </div>
  );
};

export default AboutUs;
