import React, { useState, useEffect } from "react";
import styles from "components/MediaFolder/Media.module.css";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

const MediaNav = ({ active, location }) => {
  const [activeNav, setActiveNav] = useState("recent");

  useEffect(() => {
    setActiveNav(active);
  }, [active]);

  return (
    <div className={styles.mediaNav}>
      <Container>
        <div className={styles.mediaNavflexitems}>
          <Link to="/media/recent">
            <p
              onClick={() => {
                setActiveNav("recent");
              }}
              id="recentmedia"
              className={activeNav === "recent" ? "tabactivemedia" : "recent"}
            >
              Recent
            </p>
          </Link>
          <Link to="/media/blog">
            <p
              id="blogmedia"
              onClick={() => {
                setActiveNav("blog");
              }}
              className={activeNav === "blog" ? "tabactivemedia" : "blog"}
            >
              Blog
            </p>
          </Link>
          <Link to="/media/news">
            <p
              id="blognews"
              onClick={() => {
                setActiveNav("news");
              }}
              className={activeNav === "news" ? "tabactivemedia" : "news"}
            >
              News
            </p>
          </Link>
          <Link to="/media/events">
            <p
              id="blogevents"
              onClick={() => {
                setActiveNav("events");
              }}
              className={activeNav === "events" ? "tabactivemedia" : "events"}
            >
              Events
            </p>
          </Link>
          <Link to="/media/gallery">
            <p
              id="bloggallery"
              onClick={() => {
                setActiveNav("gallery");
              }}
              className={activeNav === "gallery" ? "tabactivemedia" : "gallery"}
            >
              Gallery
            </p>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default MediaNav;
