import React from "react";
import LightSpeed from "react-reveal/LightSpeed";
import styles from "../../components/MediaFolder/Media.module.css";
import Container from "@material-ui/core/Container";
import "./Media.css";

const MediaBanner = ({ s_active, active }) => {
  return (
    <>
      <section className={styles.backgroundmediabanner}>
        <Container>
          <div className={styles.MediaBannertext}>
            <LightSpeed right>
              <p className={styles.welcometour}>Welcome to our</p>
              <h1 className={styles.mediacenter}>Media Centre</h1>
              <p className={styles.mediasstayupdated}>
                Stay updated with the latest news at Canary and the finance
                industry
              </p>
            </LightSpeed>
          </div>
        </Container>
      </section>
      <div className={styles.arrayactivnesspage}>
        <Container>
          <p style={{ fontFamily: "Gotham Rounded", color: "#6E6E6E" }}>
            Home &gt; Media &gt;{" "}
            {s_active && (
              <span style={{ color: "#6E6E6E" }}>{s_active} &gt; </span>
            )}
            {active && (
              <span style={{ color: "#21b9ec", fontWeight: 700 }}>
                {active}
              </span>
            )}
          </p>
        </Container>
      </div>
    </>
  );
};

export default MediaBanner;
