import HomeCarousel from "../components/HomePageFolder/HomeCarousel";
import HomeNewsLetter from "components/HomePageFolder/HomeNewsLetter";
import HomeSubsidiaries from "components/HomePageFolder/HomeSubsidiaries";
import WhatWeDoHome from "components/HomePageFolder/WhatWeDoHome";
import TeamBondingEvent from "components/MediaFolder/TeamBondingEvent";

const Home = () => {
  return (
    <>
      <HomeCarousel />
      <WhatWeDoHome />
      <HomeSubsidiaries />
      <TeamBondingEvent />
      <HomeNewsLetter />
    </>
  );
};

export default Home;
